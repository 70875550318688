import app from 'js/legacy-app';
import angular from 'angular';
import defaultBikeImage from 'img/no-bike.png';
import bikeLocationImg from 'img/bike-location.png';

app.controller('ViewBikeCtrl', ['$scope', '$location', 'CustomerBikeResource', '$routeParams', 'TypeOfBikes',
    '$q', 'ModalService', 'NotificationWSService', 'FrameMaterial', 'Brand', '$timeout', 'Notification',
    'AuthService', 'MetaInfo', '$bikeService', '$rootScope', 'LocationService', 'Documents',
    '$bikeComponentService', 'SaleCertificateService', 'User', 'ErrorHandler', 'ToggleLD',
    function ($scope, $location, CustomerBikeResource, $routeParams, TypeOfBikes, $q, ModalService, NotificationWSService,
              FrameMaterial, Brand, $timeout, Notification, AuthService, MetaInfo, $bikeService, $rootScope,
              LocationService, Documents, $bikeComponentService, SaleCertificateService, User, ErrorHandler, ToggleLD) {

        let vbc = {
            bikeId: $routeParams.bikeId,
            resource: $bikeService.getResourceClass()
        };

        $scope.vbc = {};

        $scope.bikeHistory = {
            id: vbc.bikeId
        };

        $scope.showBike = {
            defaultFrameSizes: ["XS", "S", "M", "L", "XL", "XXL", "Do not know"],
            defaultWheelSizes: [
                {inch: "29\"", iso: "622 mm", bicycleStandard: "700C"},
                {inch: "27.5\"", iso: "584 mm", bicycleStandard: "650B"},
                {inch: "26\"", iso: "559 mm", bicycleStandard: ""},
                {inch: "24\"", iso: "507 mm", bicycleStandard: ""},
                {inch: "Do not know", iso: "", bicycleStandard: ""},
            ],
            countColor: 1,
            types: [],
            map: {
                options: {
                    center: {lat: 41.742295, lng: -73.355499}
                },

                markers: [{
                    position: {lat: 41.742295, lng: -73.355499},
                    title: 'Bicycle home',
                    icon: require('img/bike-location.png')
                }]
            },
            stationId: $routeParams.stationId,
            organisationId: $routeParams.organisationId,
            requestId: $routeParams.requestId,
            disabledChangeLocation: false,
            isUser: AuthService.isUser(),
            isOrganisation: AuthService.isBikeOrganisationOwner(),
            isActivatedUser: AuthService.isActivatedUser(),
            isMechanic: AuthService.isMechanic(),
            targetBlank: $routeParams.targetBlank ? $routeParams.targetBlank : false
        };

        if (!$scope.showBike.organisationId && AuthService.isBikeOrganisationOwner()) {
            $scope.showBike.organisationId = AuthService.user().companyId;
        }

        $scope.showBike.maxYear = new Date().getFullYear();
        $scope.showBike.getWheelSizesString = function (wheel) {
            let result = "";
            if (!wheel)
                return result;

            if (wheel.inch) {
                result += wheel.inch;
                if (wheel.iso || wheel.bicycleStandard)
                    result += " / ";
            }
            if (wheel.iso) {
                result += wheel.iso;
                if (wheel.bicycleStandard)
                    result += " / ";
            }
            if (wheel.bicycleStandard)
                result += wheel.bicycleStandard;

            return result;
        };

        $scope.positionImages = {};
        $scope.disabledMainDescription = true;
        $scope.disabledAdditionalDescription = true;

        // START change components
        $scope.addComponent = function () {
            $bikeComponentService.addComponent($scope.bike.components);
        };

        $scope.getBrands = function (brand) {
            return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
        };

        $scope.getComponents = function (searchText) {
            return $bikeComponentService.getComponents($scope, searchText);
        };

        $scope.saveAdditionalDescription = function (form) {
            $bikeComponentService.saveAdditionalDescription({
                bike: $scope.bike,
                form: form
            });
            $scope.certification = SaleCertificateService.getCertificationInfo($scope.bike.totalCondition);
        };

        $scope.isMechanic = function () {
            return false;
        };

        $scope.currentUserIsMechanic = function () {
            return AuthService.isMechanic();
        };
        // END change components

        // START change main description
        $scope.toggleMainDescription = function () {
            $scope.disabledMainDescription = !$scope.disabledMainDescription;
        };

        $scope.saveMainDescription = function (form) {
            if (form.$valid) {
                let countColors = 0;
                let colors = $scope.bike.colors;
                angular.forEach(colors, function (color) {
                    if (color) {
                        countColors++;
                    }
                });

                let colorsArray = countColors === parseInt($scope.showBike.countColor) ? colors : [];
                vbc.resource.updateMainDesc({id: vbc.bikeId}, {
                    make: $scope.bike.make,
                    model: $scope.bike.model,
                    year: $scope.bike.year,
                    colors: !colorsArray ? [] : colorsArray,
                    wheelSize: $scope.bike.wheelSize,
                    frameMaterial: $scope.bike.frameMaterial,
                    frameSize: $scope.bike.frameSize
                }, function (data) {
                    Notification.success('Bicycle description has been updated');
                    setBike(data);
                    vbc.setWheelSize(data);
                    $scope.toggleMainDescription();
                }, function (error) {
                    ErrorHandler.onError(error);
                });
            }
        };

        $scope.changeColor = function () {
            while ($scope.bike.colors.length > parseInt($scope.showBike.countColor)) {
                $scope.bike.colors.splice(0, 1);
            }
        };

        $scope.chooseColorLabel = function () {
            if (!$scope.bike.colors || $scope.bike.colors.length !== parseInt($scope.showBike.countColor)) {
                return 'Select';
            }

            return '';
        };

        $scope.isSelectColor = function () {
            if (!$scope.bike.colors) {
                return '';
            }
            return $scope.bike.colors.length === parseInt($scope.showBike.countColor) ? 'active' : '';
        };
        // END change main description


        // START gallery
        $scope.uploadNewImage = function ($event) {
            ModalService.uploadImages($event, Documents.bikeImage(vbc.bikeId), function (images) {
                for (let i in images) {
                    let image = {
                        id: images[i].id,
                        original: images[i].original,
                        thumbnail: images[i].thumbnail
                    };
                    $scope.bike.images.push(image);
                }
                refreshAvatar();
            });
        };

        $scope.showPicture = function ($event, index) {
            let getImages = function () {
                return $scope.bike.images;
            };
            if ($scope.isCanEdit()) {
                ModalService.showPicture($event, getImages, index, $scope.showBike.remove, $scope.showBike.setDefault);
            } else {
                ModalService.showPicture($event, getImages, index, null, null);
            }
        };

        $scope.showBike.remove = function (index, successCallback) {
            Documents.bikeImage(vbc.bikeId).remove($scope.bike.images[index].id).then(
                function () {
                    $scope.bike.images.splice(index, 1);
                    refreshAvatar();
                    if (successCallback) {
                        successCallback();
                    }
                    Notification.success("Image has been successfully removed");
                }, function (error) {
                    Notification.error("Failed to remove the image");
                }
            );
        };

        $scope.showBike.setDefault = function (index, callback) {
            Documents.bikeImage(vbc.bikeId).setAsAvatar($scope.bike.images[index].id).then(function (response) {
                Notification.success('Image was successfully set as a bicycle avatar');
                $scope.bike.images = response.data.images;
                refreshAvatar();
                if (callback) {
                    callback();
                }
            });
        };

        let refreshAvatar = function () {
            if ($scope.bike.images && $scope.bike.images.length > 0) {
                $scope.showBike.avatar = $scope.bike.images[0].thumbnail;
            } else {
                $scope.showBike.avatar = defaultBikeImage;
            }
        };
        // END gallery

        //Listen the location changes
        $scope.$watch('bike.fixLocation.address', function () {
            if (!($scope.bike && $scope.bike.fixLocation)) {
                return;
            }

            let address = $scope.bike.fixLocation;
            let lat = address.latitude;
            let lng = address.longitude;

            $scope.showBike.map = {
                options: {
                    center: {lat: lat, lng: lng}
                },

                markers: [{
                    position: {lat: lat, lng: lng},
                    title: 'Bicycle home',
                    icon: bikeLocationImg
                }]
            };
        });

        $scope.saveFixAddress = function (form) {
            form.$setSubmitted();
            if (!vbc.validBikeAddress($scope.bike.fixLocation)) {
                return;
            }

            $scope.bike.fixLocation = LocationService.mapAddress($scope.bike.fixLocation);
            vbc.resource.updateFixAddress({id: vbc.bikeId}, $scope.bike.fixLocation, function (data) {
                setBike(data);
                Notification.success('Address was successfully saved');
            }, function (error) {
                ErrorHandler.onError(error, "Failed to change location");
            });
        };

        vbc.validBikeAddress = function (address) {
            let error = LocationService.validateBikeAddress(address);
            if (error) {
                Notification.error(error);
                return false;
            }

            return true;
        };

        $scope.onTabSelected = function (tab) {

            if ($routeParams[tab]) {
                return;
            }

            let searchParams = {};
            if ($routeParams.targetBlank) {
                searchParams.targetBlank = $routeParams.targetBlank;
            }
            if ($routeParams.requestId) {
                searchParams.requestId = $routeParams.requestId;
            }
            searchParams[tab] = true;
            $location.search(searchParams);
            $location.replace();
        };

        $scope.changeIndexTab = function () {
            if ($routeParams.info) {
                $scope.selectedTab = 0;
                $scope.title = 'Bicycle info';
            } else if ($routeParams.history) {
                $scope.selectedTab = 1;
                $scope.title = 'History';
            } else {
                $scope.selectedTab = 0;
                $scope.title = 'Bicycle Info';
            }
        };

        $scope.changeIndexTab();

        $scope.$on('$routeChangeSuccess', function () {
            $scope.changeIndexTab();
        });

        $scope.changeStatus = function ($event) {
            ModalService.changeBikeStatus($event, $scope.bike, function (newStatus) {
                $bikeService.getResourceClass().changeStatus({id: vbc.bikeId}, {
                    status: newStatus
                }, function (data) {
                    setBike(data);
                    Notification.success('Status was successfully changed');
                });
            });
        };

        $scope.selectUserForBikeTransfer = function ($event) {
            ModalService.selectUserForBikeTransfer($event, $scope.bike.id, function () {
                $scope.refresh();
            });
        };

        let refreshBikeParams = function (bike) {
            let promises = [];

            promises.push(TypeOfBikes.query(function (data) {
                $scope.showBike.types = data;
            }).$promise);


            promises.push(FrameMaterial.query(function (data) {
                $scope.materials = [];
                for (let i = 0; i < data.length; i++) {
                    $scope.materials.push(data[i].name);
                }
            }).$promise);


            let queued = $q.all(promises);
            queued.finally(function () {
                vbc.initType(bike);
                vbc.setWheelSize(bike);
            });
            return queued;
        };


        function getPublicUserInfo(data) {
            return User.getPublicUserInfo({id1: data.userId},
                function (user) {
                    $scope.user = user;
                }, function (error) {
                    Notification.error('Unable to load bicycle owner');
                }).$promise;
        }

        $scope.refresh = function () {
            let deferred = $q.defer();

            vbc.resource.get({id: vbc.bikeId}, function (data) {
                if (data.userId && AuthService.isLogin()) {
                    $q.all([refreshBikeParams(data), getPublicUserInfo(data)])
                        .finally(function () {
                            setBike(data, true);
                            deferred.resolve();
                        });
                } else {
                    refreshBikeParams(data).finally(function () {
                        deferred.resolve();
                    });
                    setBike(data, true);
                }
            }, function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        $scope.isCanEdit = function () {
            let user = AuthService.user();
            let canEdit = false;
            if (user && $scope.bike && $scope.bike.userId) {
                canEdit = $scope.bike.userId === user.id;
            }
            if (!canEdit && user && $scope.bike) {
                canEdit = $scope.bike.canEdit && (user.role === 'MECHANIC' ||
                    user.role === 'BIKE_ORGANIZATION_OWNER');
            }
            return canEdit;
        };

        $scope.isCanEditLocation = function () {
            return $scope.isCanEdit() && AuthService.user().role !== 'MECHANIC';
        };

        $scope.isCanEditComponent = function () {
            let user = AuthService.user();

            let canEdit = false;
            if (user && $scope.bike && $scope.bike.userId) {
                canEdit = $scope.bike.userId === user.id;
            }

            if (!canEdit && user && $scope.bike) {
                canEdit = $scope.bike.canEdit && (
                    user.role === 'BIKE_ORGANIZATION_OWNER');
            }

            if (canEdit) {
                return true;
            }

            return $scope.bike ? $scope.bike.canEdit : false;
        };

        $scope.isCanEditMainDesc = function () {
            return $scope.isCanEditLocation();
        };

        $scope.canTransferBike = function () {
            return $scope.isCanEditMainDesc() && $scope.currentUserId === $scope.bike.userId;
        };

        function setBike(data, refresh) {

            $scope.isSaleCertificate = data.totalCondition;

            if (!refresh) {
                data.components = $scope.bike.components;
                data.make = $scope.bike.make;
                data.model = $scope.bike.model;
                data.year = $scope.bike.year;
                data.colors = $scope.bike.colors;
                data.wheelSize = $scope.bike.wheelSize;
                data.frameMaterial = $scope.bike.frameMaterial;
                data.frameSize = $scope.bike.frameSize;
                if ($scope.showBike.fixLocationPlace) {
                    data.fixLocation = {
                        address: $scope.bike.fixLocation.address,
                        latitude: $scope.showBike.fixLocationPlace.geometry.location.lat(),
                        longitude: $scope.showBike.fixLocationPlace.geometry.location.lng(),
                        placeId: $scope.showBike.fixLocationPlace.place_id
                    };
                }
            } else {
                data.components = $bikeComponentService.transformComponents(data);
                data.frameCondition = {
                    description: data.frameCondition ? data.frameCondition.description : ''
                };
            }

            $scope.bike = data;
            $scope.bike.canEdit = data.canEdit;
            let thumbnail;
            if ($scope.bike.images && $scope.bike.images.length > 0) {
                $scope.showBike.avatar = $scope.bike.images[0].thumbnail;
                thumbnail = $scope.bike.images[0].thumbnail;
            } else {
                $scope.showBike.avatar = defaultBikeImage;
            }
            if ($scope.bike.colors) {
                $scope.showBike.countColor = parseInt($scope.bike.colors.length);
            } else {
                $scope.bike.colors = [];
            }
            if (!$scope.showBike.countColor) {
                $scope.showBike.countColor = 1;
            }
            $scope.showBike.showColors = true;

            let title = $scope.bike.make + ', ' + $scope.bike.model;
            let description = $scope.bike.make + ' ' + $scope.bike.model;
            let keywords = $scope.bike.make + ', ' + $scope.bike.model;

            if ($scope.bike.year) {
                description += ' ' + $scope.bike.year;
            }
            if ($scope.bike.frameMaterial) {
                description += ' ' + $scope.bike.frameMaterial;
            }

            if ($scope.bike.serialNumber) {
                title += ', ' + $scope.bike.serialNumber;
                description += ' - ' + $scope.bike.serialNumber;
                keywords += ', ' + $scope.bike.serialNumber;
            }

            MetaInfo.manualChange(title, description, keywords, thumbnail);

            $scope.certification = SaleCertificateService.getCertificationInfo($scope.bike.totalCondition);
        }

        vbc.setWheelSize = function (bike) {
            if ($scope.showBike.currentType && bike.wheelSize) {
                for (let i in $scope.showBike.currentType.wheels) {
                    let wheel = $scope.showBike.currentType.wheels[i];
                    if (wheel.inch + wheel.iso + wheel.bicycleStandard ===
                        bike.wheelSize.inch + bike.wheelSize.iso + bike.wheelSize.bicycleStandard) {
                        bike.wheelSize = wheel;
                        break;
                    }
                }
            } else {
                $scope.showBike.currentType = {
                    wheels: $scope.showBike.defaultWheelSizes,
                    frameSizes: $scope.showBike.defaultFrameSizes
                };
            }

        };

        vbc.initType = function (bike) {
            for (let i in $scope.showBike.types) {
                if ($scope.showBike.types[i].name === bike.type) {
                    $scope.showBike.currentType = $scope.showBike.types[i];
                    break;
                }
            }
        };

        $bikeComponentService.queryBrand($scope);

        $scope.chooseColor = function ($event) {
            if (!$scope.disabledMainDescription) {
                ModalService.chooseColor($event, $scope.bike.colors, parseInt($scope.showBike.countColor), function (response) {
                    if (response) {
                        $scope.bike.colors = response;
                    }
                });
            }
        };

        $scope.getStatusClass = function () {
            if ($scope.bike && $scope.bike.status) {
                return $scope.bike.status.toLowerCase();
            } else {
                return '';
            }
        };

        $scope.enterSN = function ($event) {
            ModalService.enterBikeSN($event, function (response) {
                if (response) {
                    let callback = function () {
                        vbc.resource.enterSN({id: vbc.bikeId}, {serialNumber: response}, function (data) {
                            setBike(data);
                            $scope.bikeHistory.refreshHistory();
                        }, function (err) {
                            Notification.error(err.message);
                        });
                    };
                    $bikeService.checkBikeStatus(response, callback, callback);
                }
            });
        };

        $scope.getColorsClass = function (colors) {
            if (colors && colors.length === 1 && colors[0] === 'black') {
                return 'color-border';
            }
            return '';
        };

        $scope.getImagesWidth = function () {
            return {
                width: ($scope.bike.images.length * 100) + 'px'
            };
        };

        $scope.showPublicLink = function ($event) {
            ModalService.showPublicBikeLink($event, $scope.bike.id);
        };

        $scope.duplicateBike = function ($event) {
            let message = 'Are you sure you want to duplicate this bicycle?';
            ModalService.yesNoMessage(null, '', message, function (response) {
                if (!response) return;

                ToggleLD.show();
                $bikeService.getResourceClass().duplicate({id: $scope.bike.id}, {}, function (data) {
                    ToggleLD.hide();
                    Notification.success("Bicycle successfully duplicated");
                    $location.path('/bike/' + data.id).search({});
                    $scope.refresh();
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to duplicate bicycle");
                });
            });
        };

        $scope.addCustomer = ($event) => {
            ModalService.showCustomerInvitationDialog($event, vbc.bikeId).then(function (data) {
                if (!data)  return;

                let msg = 'Would you like to schedule service for this customer (Assembly/Repair)?';
                ModalService.yesNoMessage(null, '', msg, function (response) {
                    if (!response) return;

                    $location.path('/create-request').search({
                        address: data.address,
                        bikeId: data.bikeId,
                        customerId: data.customerId,
                        apartment: data.apartment
                    });
                });
            });
        };

        $scope.resendConfirmationEmail = function ($event) {
            ModalService.consultationMessage($event, 'Are you sure you want to resend confirmation email', function (response) {
                if (response === true) {
                    $bikeService.getResourceClass().resendConfirmationEmail({id: $scope.bike.id}, function (data) {
                        Notification.success('Confirmation email was successfully resent');
                    }, function (error) {
                        ErrorHandler.onError(error);
                    });
                }
            });
        };

        $scope.getHrefAssignUserButton = function (bike) {
            if (!bike) return "";

            if (AuthService.isBikeBusinessSupervisor()) {
                if ($scope.showBike.organisationId && $scope.showBike.stationId) {
                    return '/organisation/' + $scope.showBike.organisationId + '/station/' + $scope.showBike.stationId + '/bike/' + bike.id + '/add-owner';
                } else if ($scope.showBike.organisationId && !$scope.showBike.stationId) {
                    return '/organisation/' + $scope.showBike.organisationId + '/bike/' + bike.id + '/add-owner';
                }
            } else if (AuthService.isBikeOrganisationOwner()) {
                if ($scope.showBike.organisationId && $scope.showBike.stationId) {
                    return '/organisation/' + $scope.showBike.organisationId + '/station/' + $scope.showBike.stationId + '/bike/' + bike.id + '/add-owner';
                } else if ($scope.showBike.organisationId && !$scope.showBike.stationId) {
                    return '/organisation/' + $scope.showBike.organisationId + '/bike/' + bike.id + '/add-owner';
                }
            }

            return "";
        };

        $scope.getBikeOwnerHref = function () {
            if (!$scope.bike) {
                return;
            }

            let href = '/user/' + $scope.user.id + '?bikeId=' + $scope.bike.id;
            if ($routeParams.organisationId) {
                href += '&organisationId=' + $routeParams.organisationId;
            }
            if ($scope.showBike.stationId) {
                href += '&stationId=' + $routeParams.stationId;
            }

            return href;
        };

        $scope.getCreateRequestHref = function (bike) {
            let defaultAddress = vbc.getDefaultRequestAddress(bike);
            let href = '/create-request?bikeId=' + bike.id;

            if ($routeParams.organisationId) {
                href += '&organisationId=' + $routeParams.organisationId;
                if ($scope.showBike.stationId) {
                    href += '&stationId=' + $scope.showBike.stationId;
                }
            }

            if (defaultAddress && defaultAddress.address) {
                href += '&address=' + defaultAddress.address;
            }

            if (defaultAddress && defaultAddress.apartment) {
                href += '&apartment=' + defaultAddress.apartment;
            }

            return href;
        };

        vbc.getDefaultRequestAddress = function (bike) {
            let address = null;

            if (bike && bike.fixLocation) {
                address = bike.fixLocation;
            } else {
                let user = AuthService.user();
                if (user && user.locations && user.locations.length) {
                    address = user.locations[0].address;
                }
            }

            return address;
        };

        vbc.readNewNotification = function (message) {
            if (!message.data || !message.data.metadata || !message.data.metadata.bikeId) {
                return;
            }

            if (vbc.bikeId === message.data.metadata.bikeId && !message.data.read) {
                $scope.refresh();
                NotificationWSService.readNotification(message.data.id, AuthService.getToken());
            }
        };

        vbc.readBikeNotifications = function () {
            NotificationWSService.send({
                operation: 2,
                bikeId: vbc.bikeId,
                token: AuthService.getToken().access_token
            });
        };

        (function init() {
            ToggleLD.show();
            $scope.refresh().finally(function () {
                $scope.vbc.loaded = true;
                ToggleLD.hide();
            });
            AuthService.currentUser(function (user) {
                $scope.currentUserId = user.id;
                $scope.showOwner = AuthService.isBikeOrganisationOwner() || AuthService.isBikeBusinessSupervisor();
            });
            angular.element(document).ready(function () {
                NotificationWSService.registerHandler("NEW", "view-bike", vbc.readNewNotification);
                vbc.readBikeNotifications();
            });
            $scope.$on("$destroy", function () {
                NotificationWSService.unregisterHandler("NEW", "view-bike");
            });
        })();
    }

]);
