import app from "js/legacy-app";

app.filter('userRole', function () {
    return function getUserType(userRole) {
        switch (userRole) {
            case 'BIKE_ORGANIZATION_OWNER':
                return 'Organization';
            case 'ADMIN':
                return 'Velotooler Support';
            case 'USER':
                return 'Bike Owner';
            case 'MECHANIC':
                return 'Mechanic';
            case 'PARTIALLY_REGISTERED':
                return 'Partially Registered';
            default:
                return '';
        }
    };
});
