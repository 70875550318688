import './autocomplete.component.scss';

export default () => {
    return {
        restrict: 'E',
        replace: true,
        require: ['ngModel'],
        scope: {
            ngModel: "=ngModel",
            disabled: "@disabled",
            clazz: "@vuiClass",
            searchText: "=vuiSearchText",
            placeholder: "@vuiPlaceholder",
            delay: '@vuiDelay', // delay for loading in milliseconds
            minLength: '@vuiMinLength', // min count of letters for loading in milliseconds
            itemName: '@vuiItemName',
            name: '@vuiName',
            id: '@vuiId',
            required: '@vuiRequired',
            clearButton: '&vuiClearButton',
            search: '=vuiSearchFn',
            displayItem: '=vuiDisplayItemFn',
            onChange: '=vuiOnChange',
            showIcon: '=vuiShowIcon'
        },
        template: (element, attr) => {
            const getItemTemplate = () => {
                const templateTag = element.find('vui-item').detach(),
                    html = templateTag.length ? templateTag.html() : element.html();
                const itemName = element.attr('vui-item-name');
                if (!templateTag.length) element.empty();
                return '<vui-option ng-repeat="' + itemName + ' in items" vui-value="' + itemName + '">' + html + ' </vui-option>';
            }

            const getNoItemsTemplate = () => {
                const templateTag = element.find('vui-not-found').detach();
                const template = templateTag.length ? templateTag.html() : '';
                return template ? '<vui-option ng-if="notFoundVisible()">' + template + '</vui-option>' : '';
            }

            const noItemsTemplate = getNoItemsTemplate();
            const itemTemplate = getItemTemplate();

            return '\
            <div class="vui-autocomplete" ng-class="{\'vui-autocomplete--show-close\': ngModel && showClearButton}">\
                <input type="text" \
                       id="{{id}}"\
                       name="{{name}}"\
                       class="vui-input" \
                       ng-class="{{clazz}}" \
                       placeholder="{{placeholder}}" \
                       ng-disabled="{{disabled}}" \
                       ng-value="ngModel" \
                       ng-change="onChangeText(searchText)" \
                       ng-model="searchText"\
                       ng-required="{{required}}"\
                       vui-rm-autocomplete>\
                       <vui-input-group ng-if="showIcon">\
                           <svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">\
                               <g>\
                                   <path d="M20.1668 11.0832C20.1668 15.0149 14.6381 20.027 12.1681 22.0701C11.4848 22.6353 10.5155 22.6353 9.83225 22.0701C7.36219 20.027 1.8335 15.0149 1.8335 11.0832C1.8335 6.02056 5.93755 1.9165 11.0002 1.9165C16.0628 1.9165 20.1668 6.02056 20.1668 11.0832Z" stroke="#14142B" stroke-width="2"/>\
                                   <ellipse cx="11" cy="11.0835" rx="2.75" ry="2.75" stroke="#14142B" stroke-width="2"/>\
                               </g>\
                           </svg>\
                       </vui-input-group>\
                       <span ng-click="clearAndClose()" class="vui-autocomplete__close tw-cursor-pointer">\
                       <svg version="1.1" x="0px" y="0px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" \
                       fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false"><g>\
                       <path d="M19 6.41l-1.41-1.41-5.59 5.59-5.59-5.59-1.41 1.41 5.59 5.59-5.59 5.59 \
                       1.41 1.41 5.59-5.59 5.59 5.59 1.41-1.41-5.59-5.59z"></path></g></svg></span>\
                <vui-dropdown-list>\
                    ' + itemTemplate + ' \
                    ' + noItemsTemplate + '\
                </vui-dropdown-list>\
                <div class="vui-autocomplete__dropdown--background ignore-fullscreen-scroll" ng-click="closeList()"></div>\
            </div>';
        },
        controller: function ($scope, $timeout, $element) {
            'ngInject';

            const delay = $scope.delay | 0;
            const minLength = $scope.minLength | 0;
            let countOfCalls = 0;
            $scope.items = [];
            $scope.loading = false;
            $scope.showClearButton = $scope.clearButton();

            const dropdown = $element.find('vui-dropdown-list').children();

            $scope.setModel = (newModel) => {
                $scope.ngModel = newModel;
                $scope.closeList();
                setItemText(newModel);
            }

            $scope.$watch('ngModel', function () {
                if ($scope.onChange) {
                    $scope.onChange($scope.ngModel);
                }
                setItemText($scope.ngModel);
            });

            $scope.onChangeText = (text) => {
                openList();
                countOfCalls++;
                $timeout(() => {
                    countOfCalls--;
                    if (countOfCalls === 0 && text && text.length >= minLength) {
                        makeRequest(text);
                    }
                }, delay);
            }

            $scope.notFoundVisible = () => {
                return !$scope.loading && (!$scope.items || $scope.items.length === 0);
            }

            $scope.close = () => {
                //$scope.ngModel = undefined;
                //$scope.searchText = '';
            }

            $scope.clearAndClose = () => {
                $scope.ngModel = undefined;
                $scope.searchText = '';
            }

            const setItemText = (item) => {
                $scope.searchText = $scope.displayItem ? $scope.displayItem(item) : item;
            }

            const openList = () => {
                const rect = $element[0].getClientRects()[0];
                const marginTop = document.body.offsetWidth > 767 ? 8 : 8;
                dropdown.css('margin-top', marginTop + 'px');
                dropdown.css('width', rect.width + 'px');
                $element.addClass('vui-autocomplete--open');
            }

            $scope.closeList = () => {
                $element.removeClass('vui-autocomplete--open');
            }

            const makeRequest = (text) => {
                if ($scope.search) {
                    $scope.loading = true;
                    $scope.search(text).then((response) => {
                        $scope.items = response;
                    }).finally(() => {
                        $scope.loading = false;
                    });
                }
            }

            if (minLength === 0) makeRequest('');

            const $input = $element.find('input');
            $input.on('focusin', openList);
            $input.on('keydown', function (e) {
                const keyCode = e.which || e.keyCode;
                if (keyCode === 40) {
                    dropdown.find('div')[0].focus();
                    e.preventDefault();
                } else if (keyCode === 38) {
                    const options = dropdown.find('div');
                    options[options.length - 1].focus();
                    e.preventDefault();
                } else if (keyCode === 27) {
                    $scope.closeList();
                }
            });
        }
    };
}
