import './import-bicycles.modal.scss';

export default function ImportBicyclesModal($scope, close, groupId, onUpload, FileService, Notification, $t, ToggleLD) {
  'ngInject';

  $scope.importBicycles = {};

  $scope.chooseFile = function (files, invalidFiles) {
    if (!files || files.length === 0) {
      return;
    }

    const filesToImport = [];
    files.filter(file => FileService.isValidCsvFile(file))
      .forEach(file => filesToImport.push(file));
    invalidFiles.filter(file => FileService.isValidCsvFile(file))
      .forEach(file => filesToImport.push(file));
    if (filesToImport.length === 0) {
      return;
    }

    $scope.importBicycles.file = filesToImport[0];
  };

  $scope.import = () => {
    if (!$scope.importBicycles.file) {
      Notification.error($t('shared.modals.import_bicycles.select_file_error_message'));
    }

    ToggleLD.show();
    onUpload($scope.importBicycles.file, groupId,
      response => {
        ToggleLD.hide();
        close(response);
      },
      error => {
        ToggleLD.hide();
        Notification.error(error.data.message || $t('shared.modals.info.import_bicycles_error_message'))
      }
    );
  }

  $scope.close = () => {
    close(false);
  }
}