import OpacityImage from "img/chat/opacity.png";

import "./chat-input.component.scss";

export default {
  template: require('./chat-input.component.html'),
  bindings: {
    input: "=vuiInput"
  },
  transclude: true,
  controller: function ($element, $scope) {
    'ngInject';

    const self = this;

    $element[0].addEventListener("input", () => {
      restrictMaxLength(this);
    });

    $element.bind('keydown keypress', event => {
      if (event.which === 13) {
        if (!event.shiftKey) {
          $scope.$apply(() => {
            self.input.send();
          });

          event.preventDefault();
        }
      }
    });

    //Set cursor to input field
    setCursorToEnd();

    self.input.addSmile = cssClass => {
      insertSmileAtCursor(cssClass);
    };

    self.input.getText = () => {
      let text = $element[0].innerHTML;

      if (isEmpty(text)) {
        return null;
      }

      text = text.replace(/<div>/g, '<br>');
      text = text.replace(/<\/div>/g, '');
      return text;
    };

    self.input.clear = () => {
      $element[0].innerHTML = '';
    };

    function isEmpty(text) {
      if (!text) {
        return true;
      }

      text = text.trim();

      return !text || text.length < 1;
    }

    function insertSmileAtCursor(smile) {
      if (isMaxLength($element[0].innerText.length)) {
        return;
      }

      const img = document.createElement("IMG");
      img.setAttribute('src', OpacityImage);
      img.classList.add('smile');
      img.classList.add(smile);
      $element[0].appendChild(img);
      setCursorToEnd();
    }

    function setRange(range) {
      if (document.getSelection) {
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);

        //Scroll Bottom
        $element[0].scrollTop = $element[0].scrollHeight;
      }
    }

    function setCursorToEnd() {
      $element[0].focus();
      if (window.getSelection && document.createRange) {
        const range = document.createRange();
        range.selectNodeContents($element[0]);
        range.collapse(false);
        setRange(range);
      }
    }

    function restrictMaxLength(self) {
      const length = $element[0].innerHTML .length;

      if (isMaxLength(length)) {
        $element[0].innerHTML = $element[0].innerHTML.substring(0, 1000);
        setCursorToEnd();
      }
    }

    function isMaxLength(length) {
      const maxLength = 1000;
      return maxLength <= length;
    }
  }
};
