import angular from "angular";

const organisations = function ($resource) {
    'ngInject';

    return $resource('/api/admin-organisation/:path/:id/:walletId', {}, {
        getById: {
            method: "GET",
            isArray: false
        },
        list: {
            method: "GET",
            isArray: false
        },
        update: {
            method: "PUT",
            params: {},
            isArray: false
        },
        loadBalance: {
            method: "GET",
            params: {
                id: 'balance'
            },
            isArray: false
        },
        updateBalance: {
            method: "PUT",
            params: {
                id: 'balance'
            },
            isArray: false
        }
    });
};

export default angular.module('admin-organisations.resource', [])
    .service('AdminOrganisationsApi', organisations);
