import app from "js/legacy-app";

app.controller('UpdateBalanceDialogCtrl', ['$scope', '$routeParams', '$mdDialog', 'ToggleLD',
  'Notification', 'ErrorHandler', '$q', 'AuthService', 'balance',
  function ($scope, $routeParams, $mdDialog, ToggleLD, Notification, ErrorHandler, $q, AuthService, balance) {

    $scope.balance = {
      amount: balance.amount / 100,
      creditLimit: balance.creditLimit / 100,
    };

    $scope.cancel = function () {
      $mdDialog.cancel();
    };

    $scope.submitForm = function (form) {
      if (form.$valid) {
        $mdDialog.hide($scope.balance);
      }
    };

  }]);
