import "./switch.component.scss";

export default {
  template: require('./switch.component.html'),
  transclude: true,
  bindings: {
    onChange: "=?vuiChange",
    ngModel: "=ngModel",
    disabled: '<?vuiDisabled'
  },
  controller: function () {
    'ngInject';

    const self = this;

    self.onClick = () => {
      if (!self.disabled) {
        self.ngModel = !self.ngModel;
        if (self.onChange) {
          self.onChange(self.ngModel);
        }
      }
    }
  }
};
