export default function OrganisationManagerPermissionsProvider() {
  this.$get = (AuthService, $routeParams) => {
    'ngInject';

    function OrganisationManagerPermissionsProvider() {

      const self = this;

      self.hasPermission = (permission, callback) => AuthService.currentUser(() => {
        if (!AuthService.isBikeOrganisationOwner()) {
          callback(false);
          return;
        }

        const organisations = AuthService.user().organisations;
        if (!organisations || !organisations.find(org => org.id === $routeParams.orgId)) {
          callback(false);
          return;
        }

        let currentOrganisation = organisations.find(org => org.id === $routeParams.orgId);
        if (currentOrganisation.status !== 'ACTIVE') {
          callback(false);
          return;
        }

        const currentPermission = currentOrganisation.role;
        let permit;

        if (currentPermission === 'ACCOUNT_MANAGER' || permission === 'VIEWER') {
          permit = true;
        } else if (currentPermission === permission && permission === 'MANAGER') {
          permit = true;
        } else {
          permit = false;
        }

        callback(permit)
      })
    }

    return new OrganisationManagerPermissionsProvider();
  };
}