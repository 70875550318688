import angular from 'angular';

(function () {

    'use strict';

    function LocationService(LocationResource, $mdDialog, ToggleLD, ErrorHandler, User, Notification) {

        var currentCoordinates = {
            lat: '',
            lng: ''
        };

        this.validateAddress = function (address) {
            if (!address) {
                return "Please enter valid address";
            }
            if (!address.building) {
                return "We could not recognize your address. " +
                    "Please make sure you enter a full address, including street and building numbers";
            }
            if (!address.country) {
                return "Please make sure your address contains country field";
            }

            let supportedCountries = ['united states', 'united kingdom', 'australia', 'canada'];
            if (supportedCountries.indexOf(address.country.toLowerCase()) < 0) {
                return "Service is not available in your country";
            }

            return null;
        };

        this.validateProfileAddress = function (address) {
            if (!address) {
                return "Please enter valid address";
            }
            if (!address.building) {
                return "We could not recognize your address. " +
                    "Please make sure you enter a full address, including street and building numbers";
            }

            return null;
        };

        this.validateWorkAreaAddress = function (address) {
            if (!address) {
                return "Please enter valid address";
            } else if (!address.country) {
                return "Please make sure your address contains country field";
            }

            return null;
        };

        this.validateBikeAddress = function (address) {
            if (!address) {
                return "Please enter valid address";
            }
            if (!address.building) {
                return "We could not recognize your address. " +
                    "Please make sure you enter a full address, including street and building numbers";
            }
            if (!address.country) {
                return "Please make sure your address contains country field";
            }
            if (!address.city) {
                return "We could not recognize your address. Please make sure you enter a full address, including city";
            }
        };

        this.setLocationOnChange = function (location) {
            try {
                var lat = location.coordinates.lat;
                var lng = location.coordinates.lng;
                location.map.clear();
                location.map.addMarker(lat, lng, location.marker);

                currentCoordinates.lat = lat;
                currentCoordinates.lng = lng;
            } catch (e) {
                throw new Error('Failed to define the location');
            }
        };

        this.mapAddress = function (location) {
            if (location && location.address) {
                return {
                    address: location.address,
                    placeId: location.placeId,
                    longitude: location.longitude,
                    latitude: location.latitude,
                    googleAddress: location.googleAddress,
                    country: location.country,
                    city: location.city,
                    county: location.county,
                    state: location.state,
                    street: location.street,
                    building: location.building,
                    zip: location.zip,
                    offset: location.offset > 60 ? location.offset / 60 : location.offset,
                    apartment: location.apartment,
                    bounds: location.bounds
                };
            } else {
                return null
            }
        };

        this.mapProfileAddress = function (profileAddress) {
            var address = this.mapAddress(profileAddress.address);
            return {
                id: profileAddress.id,
                address: address,
                type: profileAddress.type,
                description: profileAddress.description
            };
        };

        this.editProfileAddress = function (location, $event) {
            $event.stopPropagation();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/profile/address/profile-address-edit.html',
                locals: {
                    model: location
                },
                controller: 'ProfileAddressEditDialogController'
            });
        };

        this.removeProfileAddress = function (location, $event) {
            $event.stopPropagation();
            ToggleLD.show();
            return User.removeProfileAddress({id1: location.id}, function () {
                ToggleLD.hide();
                Notification.success("Address has been removed");
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error, "Failed to remove address");
            }).$promise;
        };

        this.getLocationOffset = function (location, callback) {
            LocationResource.getTimezone({
                lat: location.latitude,
                lng: location.longitude
            }, function (response) {
                var rawOffset = response.rawOffset;
                var dstOffset = response.dstOffset;

                if (isNaN(rawOffset) || isNaN(dstOffset)) {
                    callback(0);
                } else {
                    callback((rawOffset + dstOffset) / 3600);
                }
            }, function (error) {
                callback(false);
            });
        };

        this.getLocationIconClass = function (locationType) {
            var locationTypeIcons = {
                'HOME': 'fa fa-home',
                'WORK': 'fa fa-briefcase',
                'OTHER': 'fa fa-star'
            };

            return locationTypeIcons[locationType] || locationTypeIcons.OTHER;
        };

        this.filterLocationByType = function (locations, locationType) {
            var filtered = locations ? locations.filter(function (location) {
                return location.type === locationType;
            }) : [];
            return filtered.length > 0 ? filtered[0] : {type: locationType};
        };

        this.serializeAddress = function (address) {
            var result = "";
            result += (address.googleAddress ? address.googleAddress : "") + ";";
            result += (address.zip ? address.zip : "") + ";";
            result += (address.city ? address.city : "") + ";";
            result += (address.state ? address.state : "") + ";";
            result += (address.country ? address.country : "") + ";";
            result += (address.longitude ? address.longitude : "") + ";";
            result += (address.latitude ? address.latitude : "") + ";";
            result += (address.bounds ? address.bounds.north : "") + ";";
            result += (address.bounds ? address.bounds.east : "") + ";";
            result += (address.bounds ? address.bounds.south : "") + ";";
            result += (address.bounds ? address.bounds.west : "");

            return result;
        };

        this.deserializeAddress = function (string) {
            var parts = string.split(';'), result = {};

            if (parts.length !== 11) {
                return null;
            }

            result.address = parts[0];
            result.zip = parts[1];
            result.city = parts[2];
            result.state = parts[3];
            result.country = parts[4];
            result.longitude = parts[5];
            result.latitude = parts[6];

            if (parts[7] && parts[8] && parts[9] && parts[10]) {
                result.bounds = {};
                result.bounds.north = parts[7];
                result.bounds.east = parts[8];
                result.bounds.south = parts[9];
                result.bounds.west = parts[10];
            }

            return result;
        };

        this.serializeBounds = function (bounds) {
            var results = [];
            results.push(bounds.south);
            results.push(bounds.west);
            results.push(bounds.north);
            results.push(bounds.east);

            return results.join(';');
        };
    }

    var app = angular.module('legacy-app');
    app.service('LocationService', ['LocationResource', '$mdDialog', 'ToggleLD', 'ErrorHandler', 'User', 'Notification', LocationService]);
}());
